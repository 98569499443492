import React, { useState, useEffect, useContext } from 'react';
import { Button, Modal, Box, Card, CardContent, Typography, Grid } from '@mui/material';
import { decryptData } from "../../../../utils/decryptData";
import { encryptData } from "../../../../utils/encryptData";
import { User_Context } from "../../../../Contexts/User";
import { API_Endpoint } from "../../../../Configuration/Settings";
import axios from "axios";
// import "./exposure.css"

const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%', // Adjust the width for mobile
    maxHeight: '80vh',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 2,
    overflowY: 'auto',
    '@media (min-width:600px)': { // Bigger screens
        width: 500,
    },
};

const cardStyle = {
    minWidth: '100%', // Full width for mobile
    padding: '8px',
    '@media (min-width:600px)': { // Bigger screens
        minWidth: 275,
    },
};

type ExposureModalProps = {
    open: boolean;
    handleClose: () => void;
    userID: string
};

const ExposureCard: React.FC<ExposureModalProps> = ({ open, handleClose, userID }) => {
    const [totalPendingBet, setTotalPendingBet] = useState([]);
    const { User } = useContext(User_Context);


    const fetchData = async () => {
        let config = {
            method: "post",
            url: `${API_Endpoint}/User/pendingBetOfUser`,
            headers: {
                Authorization: `Bearer ${User.token}`,
            },
            data: {
                token: encryptData({
                    _id: userID
                })
            }
        };
        let response = await axios.request(config);
        response.data.data = decryptData(response.data.data);
        let arr:any = [...response.data.data.payload.falconPendingBet, ...response.data.data.payload.jetfairPendingBet, ...response.data.data.payload.sattaMatkaPendingBet];
        setTotalPendingBet(arr);
    };

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <div style={{ textAlign: 'center', marginTop: '20px' }}>
            <Modal open={open} onClose={handleClose}>
                <Box sx={modalStyle}>
                    <Typography variant="h6" gutterBottom sx={{ fontSize: '1.1rem' }}>
                        Standing Bet
                    </Typography>
                    <Grid container spacing={2}>
                        {totalPendingBet.map((item: any, idx) => (
                            <Grid item xs={12} key={idx}>
                                <Card sx={cardStyle}>
                                    <CardContent>
                                        <div className="bet-content-container">
                                            {item.gameName && (
                                                <div style={{ display: 'flex', marginBottom: '8px' }}>
                                                    <Typography variant="body2" color="textSecondary">
                                                        {item.gameName} Bet Amt.:
                                                    </Typography>
                                                    <Typography variant="body2" sx={{ marginLeft: '4px' }}>
                                                        {item.amount ? item.amount : item.point ? item.point : item.Amount}
                                                    </Typography>
                                                </div>
                                            )}
                                             {(item.stake || item.Stake) && (
                                                <div style={{ display: 'flex', marginBottom: '8px' }}>
                                                    <Typography variant="body2" color="textSecondary">
                                                        Stake :
                                                    </Typography>
                                                    <Typography variant="body2" sx={{ marginLeft: '4px' }}>
                                                        {item.stake || item.Stake}
                                                    </Typography>
                                                </div>
                                            )}
                                            {item.marketName && (
                                                <div style={{ display: 'flex', marginBottom: '8px' }}>
                                                    <Typography variant="body2" color="textSecondary">
                                                        Market Name :
                                                    </Typography>
                                                    <Typography variant="body2" sx={{ marginLeft: '4px' }}>
                                                        {item.marketName}
                                                    </Typography>
                                                </div>
                                            )}
                                             {item.Marketname && (
                                                <div style={{ display: 'flex', marginBottom: '8px' }}>
                                                    <Typography variant="body2" color="textSecondary">
                                                        Market Name :
                                                    </Typography>
                                                    <Typography variant="body2" sx={{ marginLeft: '4px' }}>
                                                        {item.Marketname}
                                                    </Typography>
                                                </div>
                                            )}
                                            {item.bazar_name && (
                                                <div style={{ display: 'flex', marginBottom: '8px' }}>
                                                    <Typography variant="body2" color="textSecondary">
                                                        Bazar Name :
                                                    </Typography>
                                                    <Typography variant="body2" sx={{ marginLeft: '4px' }}>
                                                        {item.bazar_name}
                                                    </Typography>
                                                </div>
                                            )}
                                            {item.game_name && (
                                                <div style={{ display: 'flex', marginBottom: '8px' }}>
                                                    <Typography variant="body2" color="textSecondary">
                                                        Game Name :
                                                    </Typography>
                                                    <Typography variant="body2" sx={{ marginLeft: '4px' }}>
                                                        {item.market_code === "701" ? "INSTANT WORLI" : item.game_name}
                                                    </Typography>
                                                </div>
                                            )}
                                            {item.bazar_id && (
                                                <div style={{ display: 'flex', marginBottom: '8px' }}>
                                                    <Typography variant="body2" color="textSecondary">
                                                        Bazar Id :
                                                    </Typography>
                                                    <Typography variant="body2" sx={{ marginLeft: '4px' }}>
                                                        {item.bazar_id}
                                                    </Typography>
                                                </div>
                                            )}
                                            {item.game_id && (
                                                <div style={{ display: 'flex', marginBottom: '8px' }}>
                                                    <Typography variant="body2" color="textSecondary">
                                                        Game Id :
                                                    </Typography>
                                                    <Typography variant="body2" sx={{ marginLeft: '4px' }}>
                                                        {item.game_id}
                                                    </Typography>
                                                </div>
                                            )}
                                            {item.runnerName && (
                                                <div style={{ display: 'flex', marginBottom: '8px' }}>
                                                    <Typography variant="body2" color="textSecondary">
                                                        Runner Name :
                                                    </Typography>
                                                    <Typography variant="body2" sx={{ marginLeft: '4px' }}>
                                                        {item.runnerName}
                                                    </Typography>
                                                </div>
                                            )}
                                            {item.Runnername && (
                                                <div style={{ display: 'flex', marginBottom: '8px' }}>
                                                    <Typography variant="body2" color="textSecondary">
                                                        Runner Name :
                                                    </Typography>
                                                    <Typography variant="body2" sx={{ marginLeft: '4px' }}>
                                                        {item.Runnername}
                                                    </Typography>
                                                </div>
                                            )}
                                            {item.game_type && (
                                                <div style={{ display: 'flex', marginBottom: '8px' }}>
                                                    <Typography variant="body2" color="textSecondary">
                                                        Game Type :
                                                    </Typography>
                                                    <Typography variant="body2" sx={{ marginLeft: '4px' }}>
                                                        {item.game_type}
                                                    </Typography>
                                                </div>
                                            )}
                                            {item.rate && (
                                                <div style={{ display: 'flex', marginBottom: '8px' }}>
                                                    <Typography variant="body2" color="textSecondary">
                                                        Rate :
                                                    </Typography>
                                                    <Typography variant="body2" sx={{ marginLeft: '4px' }}>
                                                        {item.rate}
                                                    </Typography>
                                                </div>
                                            )}
                                            {item.Rate && (
                                                <div style={{ display: 'flex', marginBottom: '8px' }}>
                                                    <Typography variant="body2" color="textSecondary">
                                                        Rate :
                                                    </Typography>
                                                    <Typography variant="body2" sx={{ marginLeft: '4px' }}>
                                                        {item.Rate}
                                                    </Typography>
                                                </div>
                                            )}
                                            {item.transactionId && (
                                                <div style={{ display: 'flex', marginBottom: '8px' }}>
                                                    <Typography variant="body2" color="textSecondary">
                                                        Tran. ID :
                                                    </Typography>
                                                    <Typography variant="body2" sx={{ marginLeft: '4px' }}>
                                                        {item.transactionId}
                                                    </Typography>
                                                </div>
                                            )}
                                            {item.TransactionID && (
                                                <div style={{ display: 'flex', marginBottom: '8px' }}>
                                                    <Typography variant="body2" color="textSecondary">
                                                        Tran. ID :
                                                    </Typography>
                                                    <Typography variant="body2" sx={{ marginLeft: '4px' }}>
                                                        {item.TransactionID}
                                                    </Typography>
                                                </div>
                                            )}
                                            {item.result_date && (
                                                <div style={{ display: 'flex', marginBottom: '8px' }}>
                                                    <Typography variant="body2" color="textSecondary">
                                                        Result Date :
                                                    </Typography>
                                                    <Typography variant="body2" sx={{ marginLeft: '4px' }}>
                                                        {item.result_date}
                                                    </Typography>
                                                </div>
                                            )}
                                            {item.marketId && (
                                                <div style={{ display: 'flex', marginBottom: '8px' }}>
                                                    <Typography variant="body2" color="textSecondary">
                                                        Market ID :
                                                    </Typography>
                                                    <Typography variant="body2" sx={{ marginLeft: '4px' }}>
                                                        {item.marketId}
                                                    </Typography>
                                                </div>
                                            )}
                                            {item.Markettype && (
                                                <div style={{ display: 'flex', marginBottom: '8px' }}>
                                                    <Typography variant="body2" color="textSecondary">
                                                        Market Type :
                                                    </Typography>
                                                    <Typography variant="body2" sx={{ marginLeft: '4px' }}>
                                                        {item.Markettype}
                                                    </Typography>
                                                </div>
                                            )}
                                            {item.RunnerID && (
                                                <div style={{ display: 'flex', marginBottom: '8px' }}>
                                                    <Typography variant="body2" color="textSecondary">
                                                        Runner ID :
                                                    </Typography>
                                                    <Typography variant="body2" sx={{ marginLeft: '4px' }}>
                                                        {item.RunnerID}
                                                    </Typography>
                                                </div>
                                            )}
                                            {item.MarketID && (
                                                <div style={{ display: 'flex', marginBottom: '8px' }}>
                                                    <Typography variant="body2" color="textSecondary">
                                                        Market ID :
                                                    </Typography>
                                                    <Typography variant="body2" sx={{ marginLeft: '4px' }}>
                                                        {item.MarketID}
                                                    </Typography>
                                                </div>
                                            )}
                                            {item.betType && (
                                                <div style={{ display: 'flex', marginBottom: '8px' }}>
                                                    <Typography variant="body2" color="textSecondary">
                                                        Bet Type :
                                                    </Typography>
                                                    <Typography variant="body2" sx={{ marginLeft: '4px' }}>
                                                        {item.betType}
                                                    </Typography>
                                                </div>
                                            )}
                                            {item.BetType && (
                                                <div style={{ display: 'flex', marginBottom: '8px' }}>
                                                    <Typography variant="body2" color="textSecondary">
                                                        Bet Type :
                                                    </Typography>
                                                    <Typography variant="body2" sx={{ marginLeft: '4px' }}>
                                                        {item.BetType}
                                                    </Typography>
                                                </div>
                                            )}
                                            {item._id && (
                                                <div style={{ display: 'flex', marginBottom: '8px' }}>
                                                    <Typography variant="body2" color="textSecondary">
                                                        Bet ID :
                                                    </Typography>
                                                    <Typography variant="body2" sx={{ marginLeft: '4px' }}>
                                                        {item._id}
                                                    </Typography>
                                                </div>
                                            )}
                                            {item.game && (
                                                <div style={{ display: 'flex', marginBottom: '8px' }}>
                                                    <Typography variant="body2" color="textSecondary">
                                                        Bet On :
                                                    </Typography>
                                                    <Typography variant="body2" sx={{ marginLeft: '4px' }}>
                                                        {item.game}
                                                    </Typography>
                                                </div>
                                            )}
                                        </div>
                                    </CardContent>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                </Box>
            </Modal>
        </div>
    );
};

export default ExposureCard;
